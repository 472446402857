/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

// Custom Styles

@import './theme/star';
@import './theme/icons';
@import './theme/icons-weather';
@import './theme/icons-sport';

@import './theme/environement';
@import './app/components/progress-bar/progress-bar.component';
@import './theme/themes';
@import './theme/variables';
@import './theme/colors';

@font-face {
  font-family: 'RobotoCondensed-Medium';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/RobotoCondensed-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Jost-Regular';
  font-style: normal;
  src: url('./assets/fonts/Jost-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Jost-Medium';
  font-style: normal;
  src: url('./assets/fonts/Jost-Medium.ttf') format('truetype');
}

html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}

body,
ion-app {
  transform: none;
}

ion-footer {
  ion-toolbar {
    --background: none;
    @include themify($themes) {
      --color: themed('my-white');
    }
  }
}

.input-cover {
  position: static;
}

ion-content:not(.menu) {
  background: none !important;
}
ion-footer {
  display: flex;
  box-shadow: none !important;
  .button-actions {
    display: flex;
    margin-right: auto;
    margin-left: auto;
  }
}

ion-popover {
  ion-label.label {
    white-space: normal;
  }

  .popover-content {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

ion-nav ion-spinner {
  @include themify($themes) {
    color: themed('my-white');
  }
}
ion-nav ion-spinner.spinner-bubbles {
  color: #000;
}

ion-label.label {
  @include themify($themes) {
    color: themed('my-white');
  }
}

.input {
  @include themify($themes) {
    color: themed('primary');
  }
  text-align: center;
}

.input::-moz-placeholder {
  @include themify($themes) {
    color: themed('primary-opacity');
  }
}

.input:-ms-input-placeholder {
  @include themify($themes) {
    color: themed('primary-opacity');
  }
}

.input::-webkit-input-placeholder {
  text-indent: 0;
  @include themify($themes) {
    color: themed('primary-opacity');
  }
}

ion-label.label,
.input,
.select-placeholder,
.select-text,
.datetime,
.alert-message,
.alert-title,
ion-input[type='date'],
ion-input[type='time'],
.tab-button-text {
  font-family: Jost-Regular, sans-serif;
  // font-size: 1.6rem;
}

.select-md .select-icon,
.select-ios .select-icon {
  width: 20px;
  margin: 0 2px;
}

ion-button:not(.bar-button):not(.modal-header):not([icon-only]):not(.item-cover):not(.button-clear):not(
    .alert-tappable
  ) {
  font-family: RobotoCondensed-Medium, sans-serif;
  // font-size: 2rem;
  text-transform: uppercase;
  border-radius: 0;
  width: $button-width;
  height: $button-height;
  padding: 0;
}
ion-modal.ios {
  margin-top: env(safe-area-inset-top);
}
ion-button[icon-only] {
  width: $button-width * 0.25;
  height: $button-height;

  ion-icon {
    padding: 0;
  }
}
.tabs.mode-mobile {
  ion-button[icon-only] {
    width: $button-width-mobile * 0.25;
  }
}

.header ion-button[icon-only],
ion-button[icon-only].from-header {
  ion-icon {
    padding: 0 0.5em;
  }
}

ion-button {
  --border-style: none;
  @include themify($themes) {
    background: themed('primary');
    color: themed('my-white');
  }
}

ion-button[slot='icon-only'] {
  --border-style: none;
  --border-width: 1px;
  width: auto !important;
}

ion-button[fill='outline'] {
  background-color: transparent;
  @include themify($themes) {
    border: themed('primary-1px-solid');
    color: themed('my-white');
  }
}

ion-list[radio-group] ion-label.label {
  @include themify($themes) {
    color: themed('dark');
  }
}

ion-alert {
  .alert-head:empty {
    display: none;
  }

  .alert-message:empty {
    display: none;
  }

  .alert-message {
    margin: 30px 0 0 0;
    overflow: hidden;
  }

  * {
    @include themify($themes) {
      color: themed('dark-force');
    }
  }

  [aria-checked='true'] {
    .alert-radio-label,
    .alert-radio-icon {
      @include themify($themes) {
        color: themed('primary-important');
      }
    }
  }

  button.alert-button * {
    @include themify($themes) {
      color: themed('primary-important');
    }
  }
}

.popover-ios .popover-content,
.popover-md .popover-content {
  width: 185px;

  ion-list {
    margin-bottom: 0;

    ion-label {
      @include themify($themes) {
        color: themed('dark-force');
      }
    }
  }
}

.scroll-content {
  height: 100%;
  position: initial;
  transform: none;
}

.content-admin {
  ion-grid {
    $border-size: 4px;
    min-width: 950px;

    width: inherit;

    ion-row {
      flex-wrap: nowrap;

      ion-col {
        width: 10%;
        @include themify($themes) {
          color: themed('my-white');
        }
        display: flex;
        min-height: 40px;

        div {
          max-width: inherit;
          margin: auto 0.6rem;
        }
      }
    }
    ion-row:not(:first-child) {
      ion-col:not(:first-child) {
        line-break: anywhere;
      }
    }

    ion-row:first-child {
      text-transform: uppercase;
      font-family: RobotoCondensed-Medium, sans-serif;
      // font-size: 1.8rem;

      ion-col {
        margin: 0.4rem 0.6rem;
        @include themify($themes) {
          color: themed('primary-important');
        }

        ion-button {
          margin-left: 20px;
          background-color: transparent;

          ion-icon {
            // font-size: 1.8rem;
            @include themify($themes) {
              color: themed('primary');
            }
            opacity: 0.9;
          }
        }

        ion-icon:hover,
        ion-icon.selected {
          opacity: 1;
        }

        > span {
          cursor: pointer;
          opacity: 0.9;
        }

        > span:hover {
          opacity: 1;
        }
      }
    }

    ion-row:not(:first-child) {
      font-family: Jost-Regular, sans-serif;
      @include themify($themes) {
        background-color: themed('darkgrey-opacity');
      }

      ion-col {
        @include themify($themes) {
          border-left: themed('grey-4px-solid');
          border-top: themed('grey-4px-solid');
        }
      }

      ion-col:first-child {
        border-left: 0;
      }
    }

    ion-row:last-child {
      ion-col {
        @include themify($themes) {
          border-bottom: themed('grey-4px-solid');
        }
      }
    }

    ion-row:nth-child(2n) {
      @include themify($themes) {
        background-color: themed('my-darkgrey');
      }
    }

    ion-label {
      width: 100%;
      text-align: center;
    }

    ion-spinner {
      align-self: center;
    }

    ion-button {
      width: 20px;
      height: 20px;
      // font-size: 1rem;
    }
  }

  .filters-paginate {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
}

.button-actions {
  ion-button {
    margin: 0 10px;
    --background: none !important;
  }
  .button-solid,
  .button-outline,
  .button-clear {
    --color: none;
  }

  ion-button:first-child {
    margin-left: 0 !important;
  }

  ion-button:last-child {
    margin-right: 0 !important;
  }
}

$button-width-action: 150px;
.button-actions.mobile {
  ion-button {
    width: $button-width-action !important;
  }
}

.edition.mobile .scroll-content {
  padding: 0 0 70px 0 !important;
}

.select {
  font-family: Jost-Regular, sans-serif !important;
}

.edition {
  padding: 0 0 100px 0 !important;
  overflow-y: hidden;

  .my-content {
    margin: 0 2% 0 2%;

    @media screen and (max-width: 1300px) {
      margin: 0 1% 0 1%;
    }

    @media screen and (max-width: 640px) {
      margin: 0;
    }

    .item-md .label-md,
    .item-ios .label-ios {
      font-family: RobotoCondensed-Medium, sans-serif;
      @include themify($themes) {
        color: themed('my-white');
      }
      text-transform: uppercase;
      // font-size: 2rem;
      margin: 8px 8px 8px 0;
    }

    ion-item.item {
      background-color: transparent;
      @include themify($themes) {
        border-bottom: themed('dark-1px-solid');
      }

      ion-label.label {
        flex: none;
        text-align: left;
      }

      ion-label.label.column {
        min-width: 130px;
      }

      .label {
        width: 40%;
      }

      .label.checkbox-label {
        padding: 5px;
        width: 100%;
        max-width: 100%;
        margin: auto 0;
        text-overflow: unset;
        -webkit-box-orient: vertical;
        overflow: auto;
        white-space: normal;
      }
      // }
    }

    .input,
    ion-datetime.datetime-md,
    ion-datetime.datetime-ios,
    ion-select.select-md,
    ion-select.select-ios,
    ion-textarea.input {
      @include themify($themes) {
        color: themed('primary-important');
      }
    }

    ion-select.select,
    .item-block.no-highlight,
    .item-wrapper.no-highlight .item-block {
      @include themify($themes) {
        border-bottom: themed('primary-1px-solid');
      }
    }

    .item.item-range {
      flex-direction: inherit;
    }

    .item-input-disabled ion-label {
      opacity: 0.4;
    }
  }

  .input,
  ion-datetime.datetime-md,
  ion-datetime.datetime-ios,
  ion-select.select-md,
  ion-select.select-ios {
    margin: 0;
    height: 35px;
    max-height: 35px;
    @include themify($themes) {
      background: themed('dark');
    }
    text-align: left;
    padding: 8px 8px 8px 16px;
    width: 100%;
    max-width: 100%;

    .select-icon {
      margin-right: 5px;
    }

    .select {
      .select-text,
      .select-icon .select-icon-inner {
        @include themify($themes) {
          color: themed('primary-important');
        }
      }
    }
  }
}

// permet d'enlever le '>' de toutes les ion-list des versions iOS
.item-ios[detail-push],
ion-button.item-ios:not([detail-none]),
a.item-ios:not([detail-none]) {
  background-image: none;
  padding-right: 0;
}

//Pour enlever l'effet pixelisé sur les images redimentionné sur IE
img {
  -ms-interpolation-mode: bicubic;
}

.list-ios > .item-block:first-child,
.list-ios ion-item-sliding .item-block:first-child {
  border-top: 0;
}

.list-ios > .item-block:last-child,
.list-ios ion-item-sliding .item-block:last-child {
  border-bottom: 0;
}

ion-list[radio-group][item-content] {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;

  ion-item.item {
    border-bottom: 0 !important;
    max-width: 150px;

    * {
      @include themify($themes) {
        color: themed('primary');
      }
    }

    ion-label.label {
      flex: 1 !important;
      text-transform: none;
      font-family: Jost-Regular, sans-serif;
      // font-size: 16px;
    }
  }
}

button.alert-tappable,
.alert-radio-label {
  font-family: RobotoCondensed-Medium, sans-serif;
  // font-size: 2rem !important;
  text-transform: uppercase;
}

ion-item {
  --highlight-color-valid: transparent;
  ion-select {
    border: none !important;
  }
}
ion-textarea {
  --highlight-color-focused: transparent;
  --highlight-color-valid: transparent;
  --border-color: none;
}

ion-item.item {
  --inner-border-width: 0;
}

ion-item-sliding ion-item-options ion-button span {
  padding: 0 8px;
}

ion-app:not(.platform-mobile) ion-alert {
  .alert-wrapper {
    width: 450px !important;
    max-width: 450px !important;

    .alert-radio-group {
      .alert-radio-label {
        white-space: normal;
      }
    }

    .alert-button-group {
      min-height: 45px;
      max-height: 75px;

      ion-button {
        width: 100px !important;
        height: auto !important;
      }
    }
  }
}

.content-admin ion-chip .ion-md-close:before {
  vertical-align: sub;
  font-weight: bold;
  cursor: pointer;
  margin-top: 6.5px;
  margin-left: 1p / themx;
}

ion-action-sheet {
  .action-sheet-group {
    margin: 0 8%;
    padding: 0 !important;
    border-radius: 10px 10px 0 0;
    background-color: #fff !important;

    ion-button {
      width: 100% !important;

      .button-inner {
        justify-content: center !important;
      }
    }

    ion-button:not(:last-child) {
      border-bottom: 1px solid #000000;
    }
  }
}

.refreshDiv {
  margin: auto;
  text-align: center;
}

.has-refresher > .scroll-content {
  border-top: 0 !important;
}

ion-item.description::part(native) {
  flex-direction: column;
}

ion-textarea.text-area {
  max-height: initial !important;
}

$mobile-modal-width: 320px;
$mobile-modal-height: 550px;

:root {
  --ion-background-color: none !important;
  --color-selected: var(--ion-color-light);
  --ion-font-family: Jost-Regular, sans-serif !important;
}

ion-button[fill='clear'] {
  background-color: transparent !important;
}

ion-radio {
  opacity: 1 !important;
}

ion-select-popover {
  background-color: var(--ion-color-light) !important;
}

div.alert-wrapper {
  background-color: var(--ion-color-light) !important;
}

div.alert-wrapper > *,
.alert-button .alert-button-inner {
  color: var(--ion-color-primary) !important;
  // border-color: var(--ion-color-primary) !important;
}

.alert-button .alert-button-inner {
  font-family: RobotoCondensed-Medium, sans-serif;
  font-weight: 500;
  // font-size: 2rem;
}

ion-grid ion-row:first-child ion-col div {
  cursor: pointer;
  opacity: 0.9;
  // font-size: 1.8rem;
}
ion-col ion-button {
  --border-style: none !important;
  color: var(--ion-color-primary) !important;
}

ion-col {
  // font-size: 1.4rem;
}

.label-option {
  width: 100% !important;
}

.ion-page {
  justify-content: initial;
}

ion-checkbox[aria-checked='false']::part(container) {
  background-color: #fff;
}
ion-checkbox[aria-checked='true']::part(mark) {
  stroke: #fff;
}

ion-reorder {
  @include themify($themes) {
    color: themed('my-white');
  }
}

ion-refresher.ios {
  top: 25px !important;
}

ion-range::part(tick) {
  background: var(--bar-background-active);
}
ion-range::part(tick-active) {
  background: var(--bar-background-active);
}
ion-range::part(pin) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--bar-background-active);
  color: #fff;
  border-radius: 50%;
  // transform: scale(1.01);
  top: -20px;
  min-width: 28px;
  height: 28px;
  // transition: transform 120ms ease, background 120ms ease;
}
ion-range::part(pin)::before {
  content: none;
}
ion-range {
  --knob-border-radius: 50%;
  --knob-background: var(--bar-background-active);
  --knob-box-shadow: none;
  --knob-size: 18px;
  --bar-height: 2px;
  --bar-background: rgba(var(--ion-color-primary-rgb, 56, 128, 255), 0.26);
  --bar-background-active: var(--ion-color-primary, #3880ff);
  --bar-border-radius: 0;
  --height: 42px;
  --pin-background: var(--ion-color-primary, #3880ff);
  --pin-color: var(--ion-color-primary-contrast, #fff);
}

[popover].popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}

.datetime-btn {
  padding: 0 !important;
}
.datetime-btn::part(native) {
  width: 100%;
  text-align: left;
}

ion-button.input.btn-modal {
  width: 100% !important;
  --background: none;
  @include themify($themes) {
    background-color: themed('dark') !important;
  }
  .btn-modal-text {
    width: 100%;
    text-align: left;
    text-transform: capitalize !important;
    font-family: Jost-Regular, sans-serif !important;
    font-size: 16px;
  }
}

ion-button.input.btn-modal.lower {
  .btn-modal-text {
    text-transform: lowercase !important;
  }
}
