// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// Font path is used to include ionicons,
// roboto, and noto sans fonts
$font-path: '../assets/fonts';

// The app direction is used to include
// rtl styles in your app. For more info, please see:
// http://ionicframework.com/docs/theming/rtl-support/
$app-direction: ltr;

// @import "ionic.globals";
// @import "../global";

// Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass variables found in Ionic's source scss files.
// To view all the possible Ionic variables, see:
// http://ionicframework.com/docs/theming/overriding-ionic-variables/

// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.

$colors: (
  'primary': #31babc,
  'secondary': #91d700,
  'danger': #cc3300,
  'alert': #ff9900,
  'white': #ffffff,
  'grey': #414141,
  'dark': #121212,
  'light': #a1a1a1,
);

@import 'themes';

$popover-ios-width: 150px;
$popover-md-width: 150px;

$button-width: 220px;
$button-width-mobile: 200px;
$button-height: 48px;

$width-tabbar-web-content: 280px;
$width-tabbar-web-padding: 40px;
$width-tabbar-web: $width-tabbar-web-content + $width-tabbar-web-padding;

$height-header-bar: 5.5rem;
$height-header-title: calc(7rem + env(safe-area-inset-top) * 0.5);
$height-header-web: $height-header-bar + 8.5rem;

// Variables override Ionic
// --------------------------------------------------

//search Bar
$searchbar-md-background-color: transparent;
$searchbar-md-input-placeholder-color: #31babc;
$searchbar-md-input-text-color: #31babc;
$searchbar-md-input-search-icon-color: #31babc;

$searchbar-ios-background-color: transparent;
$searchbar-ios-input-placeholder-color: #31babc;
$searchbar-ios-input-text-color: #31babc;
$searchbar-ios-input-search-icon-color: #31babc;

//$text-input-placeholder-color: color($colors, primary, base);

// alert color part

// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here

// Alert color / size

//$alert-ios-checkbox-border-color-on: color($colors, primary, base);
//$alert-ios-checkbox-background-color-on: color($colors, primary, base);
$alert-ios-message-font-size: 2rem;

// Segment
$segment-button-ios-font-size: 3rem;

// App Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here

// Alert color / size

// $alert-md-checkbox-border-color-on: color($colors, primary, base);
// $alert-md-checkbox-background-color-on: color($colors, primary, base);
$alert-md-message-font-size: 2rem;

// Segment
$segment-button-md-font-size: 1.8rem;

// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.

// @import "ionic.theme.default";

// Ionicons
// --------------------------------------------------
// The premium icon font for Ionic. For more info, please see:
// http://ionicframework.com/docs/ionicons/

// @import "ionicons";

// Fonts
// --------------------------------------------------

// @import "roboto";
// @import "noto-sans";
