ion-app {
  background-image: url('/assets/img/bg.jpg') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  ion-nav.development {
    .bandeau,
    ion-header {
      --ion-toolbar-background: #274288 !important;
      background-color: #274288 !important;
    }
  }

  ion-nav.integration {
    .bandeau,
    ion-header {
      --ion-toolbar-background: #c3bd18 !important;
      background-color: #c3bd18 !important;
    }
  }

  ion-nav.recette {
    .bandeau,
    ion-header {
      --ion-toolbar-background: #FF5757FF !important;
      background-color: #FF5757FF !important;
    }
  }

  ion-nav.qualification {
    .bandeau,
    ion-header {
      --ion-toolbar-background: #e47920 !important;
      background-color: #e47920 !important;
    }
  }
}
