$themes: (
  dark: (
    primary: #31babc,
    primary-important: #31babc !important,
    primary-1px-solid: 1px solid #31babc,
    primary-opacity: rgba(49, 186, 188, 0.5),
    secondary: #91d700,
    secondary-important: #91d700 !important,
    danger: #cc3300,
    danger-important: #cc3300 !important,
    alert: #ff9900,
    alert-1px-solid: 1px solid #ff9900,
    light: #a1a1a1,
    light-1px-solid: 1px solid #a1a1a1,
    light-important: #a1a1a1 !important,
    light-opacity: rgba(161, 161, 161, 0.5) !important,
    my-white: #ffffff,
    white-important: #ffffff !important,
    white-1px-solid: 1px solid #ffffff,
    white-opacity: rgba(255, 255, 255, 0.5),
    my-grey: #414141,
    grey-important: #414141 !important,
    grey-1px-solid: 1px solid #414141,
    grey-4px-solid: 4px solid #414141,
    darkgrey-withoutopacity: rgb(46, 46, 46),
    darkgrey-withoutopacity-important: rgb(46, 46, 46) !important,
    my-darkgrey: rgba(28, 28, 28, 0.5),
    darkgrey-important: rgba(28, 28, 28, 0.5) !important,
    darkgrey-opacity: rgba(28, 28, 28, 0.25),
    darkgrey-opacity2: rgba(28, 28, 28, 0.5),
    darkgrey-opacity-important: rgba(28, 28, 28, 0.25) !important,
    darkgrey-1px-solid: 1px solid #2e2e2e,
    dark: #121212,
    dark-important: #121212 !important,
    dark-1px-solid: 1px solid #121212,
    white-force: #ffffff,
    white-force-important: #ffffff !important,
    dark-force: #121212,

    background-important: linear-gradient(#434343, #202020) !important,
  ),
  light: (
    primary: #31babc,
    primary-important: #31babc !important,
    primary-1px-solid: 1px solid #31babc,
    primary-opacity: rgba(49, 186, 188, 0.5),
    secondary: #669900,
    secondary-important: #669900 !important,
    danger: #cc3300,
    danger-important: #cc3300 !important,
    alert: #ff9900,
    alert-1px-solid: 1px solid #ff9900,
    light: #8d8d8d,
    light-important: #8d8d8d !important,
    light-1px-solid: 1px solid #8d8d8d,
    light-opacity: rgba(141, 141, 141, 0.5) !important,
    my-white: #000000,
    white-important: #000000 !important,
    white-1px-solid: 1px solid #000000,
    white-opacity: rgba(0, 0, 0, 0.5),
    my-grey: #fcfcfc,
    grey-important: #fcfcfc !important,
    grey-1px-solid: 1px solid #fcfcfc,
    grey-4px-solid: 4px solid #fcfcfc,
    darkgrey-withoutopacity: #dee9e7,
    darkgrey-withoutopacity-important: #dfeae8 !important,
    my-darkgrey: rgba(223, 234, 232, 0.75),
    darkgrey-important: rgba(223, 234, 232, 0.75) !important,
    darkgrey-opacity: rgba(223, 234, 232, 0.5),
    darkgrey-opacity2: rgba(223, 234, 232, 0.5),
    darkgrey-opacity-important: rgba(223, 234, 232, 0.5) !important,
    darkgrey-1px-solid: 1px solid #dfeae8,
    dark: #dfeae8,
    dark-important: #dfeae8 !important,
    dark-1px-solid: 1px solid #dfeae8,
    white-force: #ffffff,
    white-force-important: #ffffff !important,
    dark-force: #121212,

    background-important: white !important,
  ),
);

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

// Couleur du fond d'écran par défaut, qu'on peut voir juste après le splash screen (mobile)
// et avant le chargement de l'app.component
body,
ion-app,
ion-modal.mobile,
ion-modal::part(content) {
  background: linear-gradient(#434343, #202020);
}

body.theme-dark,
ion-app.theme-dark {
  ion-toggle[aria-checked='false']::part(track) {
    background-color: #fff;
  }
  ion-button[fill='clear'] {
    color: #fff;
  }
}

body.theme-light,
ion-app.theme-light {
  background: #ffffff !important;
  ion-content.content-md,
  ion-content.content-ios,
  ion-content,
  ion-modal.mobile,
  ion-modal::part(content),
  app-tabs app-header,
  .ionic-selectable-modal {
    background: #ffffff;
  }
  ion-button {
    color: #ffffff;
  }
  ion-button[fill='outline'] {
    color: #31babc;
  }
  ion-toggle[aria-checked='false']::part(track) {
    background-color: #dedede;
  }
  ion-button[fill='clear'] {
    color: #31babc;
  }
}
