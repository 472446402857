// Override Ionic Colors

// Primary
:root {
  --ion-color-primary: #31babc;
  --ion-color-primary-rgb: 49, 186, 188;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #2ba4a5;
  --ion-color-primary-tint: #46c1c3;
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

// Secondary
:root {
  --ion-color-secondary: #91d700;
  --ion-color-secondary-rgb: 145, 215, 0;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #80bd00;
  --ion-color-secondary-tint: #9cdb1a;
}

.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary);
  --ion-color-base-rgb: var(--ion-color-secondary-rgb);
  --ion-color-contrast: var(--ion-color-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-shade);
  --ion-color-tint: var(--ion-color-secondary-tint);
}

// Danger
:root {
  --ion-color-danger: #cc3300;
  --ion-color-danger-rgb: 204, 51, 0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b42d00;
  --ion-color-danger-tint: #d1471a;
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger);
  --ion-color-base-rgb: var(--ion-color-danger-rgb);
  --ion-color-contrast: var(--ion-color-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-shade);
  --ion-color-tint: var(--ion-color-danger-tint);
}

// Warning

:root {
  --ion-color-warning: #ff9900;
  --ion-color-warning-rgb: 255, 153, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e08700;
  --ion-color-warning-tint: #ffa31a;
}

.ion-color-warning {
  --ion-color-base: var(--ion-color-warning);
  --ion-color-base-rgb: var(--ion-color-warning-rgb);
  --ion-color-contrast: var(--ion-color-warning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning-shade);
  --ion-color-tint: var(--ion-color-warning-tint);
}

// Light

:root {
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
}

.ion-color-light {
  --ion-color-base: var(--ion-color-light);
  --ion-color-base-rgb: var(--ion-color-light-rgb);
  --ion-color-contrast: var(--ion-color-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-shade);
  --ion-color-tint: var(--ion-color-light-tint);
}

// Medium

:root {
  --ion-color-medium: #414141;
  --ion-color-medium-rgb: 65, 65, 65;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #393939;
  --ion-color-medium-tint: #545454;
}

.ion-color-medium {
  --ion-color-base: var(--ion-color-medium);
  --ion-color-base-rgb: var(--ion-color-medium-rgb);
  --ion-color-contrast: var(--ion-color-medium-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-shade);
  --ion-color-tint: var(--ion-color-medium-tint);
}

// Dark

:root {
  --ion-color-dark: #121212;
  --ion-color-dark-rgb: 18, 18, 18;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #101010;
  --ion-color-dark-tint: #2a2a2a;
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark);
  --ion-color-base-rgb: var(--ion-color-dark-rgb);
  --ion-color-contrast: var(--ion-color-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-shade);
  --ion-color-tint: var(--ion-color-dark-tint);
}
