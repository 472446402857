@font-face {
  font-family: 'star';
  src: url('../assets/fonts/star.ttf') format('truetype');
}

.icon-star:before {
  content: '\f006';
}
.icon-star-full:before {
  content: '\f005';
}
.icon-star-half:before {
  content: '\f089';
}
