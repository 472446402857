@import 'themes';

@font-face {
  font-family: 'icomoon-weather';
  src: url('../assets/fonts/icomoon_weather.ttf');
}

.icomoon-sport {
  font-family: 'icomoon-sport', sans-serif !important;

  > span {
    @include themify($themes) {
      background-color: themed('my-grey');
    }
    border-radius: 50%;
    padding: 0.2em;

    @media screen and (min-width: 1024px) {
      // font-size: 3em;
    }

    @media screen and (max-width: 1023px) {
      // font-size: 2.6em;
    }

    @media screen and (max-width: 640px) {
      // font-size: 2em;
    }
  }

  .biathlon:before {
    content: '\e900';
  }
  .bicycle:before {
    content: '\e901';
  }
  .hiking:before {
    content: '\e902';
  }
  .nordic-combined:before {
    content: '\e903';
  }
  .run:before {
    content: '\e904';
  }
  .ski:before {
    content: '\e905';
  }
  .tennis:before {
    content: '\e906';
  }
  .trail-running:before {
    content: '\e907';
  }
  .classic:before {
    content: '\e908';
  }
  .skate:before {
    content: '\e909';
  }
  .nologo:before {
    content: '\e910';
  }
}
