@import '../theme/themes';

@font-face {
  font-family: 'icomoon-sport';
  src: url('../assets/fonts/icomoon_sports.ttf');
}

.icomoon-weather {
  font-family: 'icomoon-weather', sans-serif !important;

  > span {
    @include themify($themes) {
      background-color: themed('my-grey');
    }
    border-radius: 50%;
    padding: 0.2em;

    @media screen and (min-width: 1024px) {
      // font-size: 3em;
    }

    @media screen and (max-width: 1023px) {
      // font-size: 2.6em;
    }

    @media screen and (max-width: 640px) {
      // font-size: 2em;
    }
  }

  .cloudy:before {
    content: '\e900';
  }
  .little-cloudy:before {
    content: '\e901';
  }
  .little-cloudy-rainy:before {
    content: '\e902';
  }
  .rainy:before {
    content: '\e903';
  }
  .rainy-snow:before {
    content: '\e904';
  }
  .rainy-thunder:before {
    content: '\e905';
  }
  .snow:before {
    content: '\e906';
  }
  .sunny:before {
    content: '\e907';
  }
  .thunder:before {
    content: '\e908';
  }
}
