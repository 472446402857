@import '../../../theme/themes.scss';

app-progress-bar,
.loading-content {
  width: 250px;

  .progress-outer {
    width: 96%;
    margin: 10px 2%;
    padding: 3px;
    text-align: center;
    @include themify($themes) {
      background-color: themed('white-opacity');
      border: themed('light-1px-solid');
      color: themed('white-opacity');
    }
    border-radius: 20px;
  }

  .progress-inner {
    min-width: 15%;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    border-radius: 20px;
    @include themify($themes) {
      background-color: themed('primary');
    }
  }

  span {
    @include themify($themes) {
      color: themed('white-opacity');
    }

    font-family: Jost-Regular, sans-serif;
  }
}
